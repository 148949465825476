import "./style.scss";
import { Box } from "@mui/material";
// import GroupPhoto from "../../../assets/img/group-photo.png";
import CompanyLogo from "../../../assets/img/Image 1.png";
import { useSearchParams } from "react-router-dom";
import { CandidateService } from "../../../services/candidate";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ThemeContext } from "../../../App";

const HeaderSection = () => {
  const themeData = useContext(ThemeContext);
  const { getCollegeDetails } = CandidateService();
  const [searchParams] = useSearchParams();
  const cpdId = searchParams.get("cpdId");
  let collegeDetails;
  if (cpdId) {
    collegeDetails = useQuery({
      queryKey: ["cpdId"],
      queryFn: () => getCollegeDetails(cpdId),
    });
  } else {
    collegeDetails = null;
  }

  const capitalize = (sentence : string | undefined) => {
    if(typeof sentence === "string"){
      return sentence
          .split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ");
    }
  };

  return (
    <Box className="header">
      <div className="header-image-wrapper">
        <img className="com-image" src={themeData && themeData.logo || CompanyLogo} alt="Company-Image" />
        <div className="header-text">
          {themeData && themeData.name || "Relinns Technologies"} x {capitalize(collegeDetails?.data?.data?.collegeId?.name)}
        </div>
      </div>
    </Box>
  );
};

export default HeaderSection;
