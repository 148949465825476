import "./style.scss";
import { Box } from "@mui/material";
import CompanyLogo from "../../../src/assets/img/Image 1.png";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CandidateService } from "../../services/candidate";
import Collabrationlogo from "../../../src/assets/img/Collaboration-.png";
import { ThemeContext } from "../../App";
import { useContext } from "react";

const ColabSection = () => {
  const themeData = useContext(ThemeContext);
  const { getCollegeDetails } = CandidateService();
  const [searchParams] = useSearchParams();
  const cpdId = searchParams.get("cpdId");
  let collegeDetails;
  if (cpdId) {
    collegeDetails = useQuery({
      queryKey: ["cpdId"],
      queryFn: () => getCollegeDetails(cpdId),
    });
  } else {
    collegeDetails = null;
  }
  return (
    <Box className="colab-header">
      <Box className="image-1">
        <img className="imge comp-img" src={themeData && themeData.logo || CompanyLogo} alt="Company-Image" />
      </Box>
      <Box className="image-1">
          <img
            src={Collabrationlogo}
            alt="collabration-sign"
            className="collabration-logo"
          />
        </Box>
        <Box className="coll-img-box image-1">
          <img
            className="imge college-image"
            src={collegeDetails?.data?.data?.collegeId?.image}
            alt="college-logo"
          />
        </Box>
  </Box>);
};

export default ColabSection;
