const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [
        (bigint >> 16) & 255, 
        (bigint >> 8) & 255,  
        bigint & 255  
    ];
  };
  
  const rgbToHex = (r: number, g: number, b:number) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  
  export const generateColorPalette = (baseColor: string) => {
    const [r, g, b] = hexToRgb(baseColor);
    
    return {
        "50": rgbToHex(Math.min(r + 200, 255), Math.min(g + 200, 255), Math.min(b + 200, 255)),
        "100": rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
        "200": rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
        "300": rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
        "400": baseColor,
        "500": baseColor,
        "600": rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
        "700": rgbToHex(Math.max(r - 100, 0), Math.max(g - 100, 0), Math.max(b - 100, 0)),
        "800": rgbToHex(Math.max(r - 150, 0), Math.max(g - 150, 0), Math.max(b - 150, 0)),
        "900": rgbToHex(Math.max(r - 200, 0), Math.max(g - 200, 0), Math.max(b - 200, 0)),
        A100: rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
        A200: rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
        A400: rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
        A700: rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
        contrastText: "#ffffff"
    };
  };