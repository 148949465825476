import { useSearchParams } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  SelectChangeEvent,
  Button,
  MenuItem,
  Link,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import { Typography } from "@mui/joy";
import Dropzone from "react-dropzone";
import "./styles.scss";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm, Controller } from "react-hook-form";
import { ICandidate } from "../../../Interface/Candidate";
import { AddCandidateValidation } from "../../../validations";
import { ICandidateField } from "../../../Interface/Candidate";
import { useNavigate, useOutletContext } from "react-router-dom";
import Select from "../../mui/select";
import MobileNumber from "../../mui/mobile-number";
import { useState, useEffect } from "react";
import { IErrorResponse } from "../../../Interface/response";
import HttpService from "../../../services/http";
import { CandidateService } from "../../../services/candidate";
import { IJobTitle } from "../../../Interface/job";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface outletProps {
  reFetch: () => void;
}
const AddCandidate = () => {
  const [searchParams] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { httpFormRequest } = HttpService();
  const { addCandidate } = CandidateService();
  const { getJobTitle } = CandidateService();
  const { getCollegeDetails } = CandidateService();

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ICandidate>({
    resolver: joiResolver(AddCandidateValidation),
    defaultValues: {
      name: "",
      email: "",
      mobileNumber: "",
      graduationYear: "",
      jobId: "",
      jobRole: "",
      degree: "",
      resumes: "",
    },
  });
  const [jobTitle, setJobTitle] = useState<IJobTitle[]>([]);
  useEffect(() => {
    const fetchJobTitle = async () => {
      try {
        const search = {
          type: JSON.stringify(["intern"]),

          status: JSON.stringify(["OPEN"]),
        };
        const response = await getJobTitle(search);
        setJobTitle(response.data);
      } catch (error) {
        console.log("Error in get Job Title", error);
      }
    };
    fetchJobTitle();
  }, []);

  const [state, setState] = useState({
    mobileNumber: {
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN",
    },
    mobileNumberSecondary: {
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN",
    },
  });

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    const data = value.split(":");

    setState((prev) => ({
      ...prev,
      [name]: {
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      },
    }));
  };
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const urlcpdId = searchParams.get("cpdId");
  let collegeDetails;
  if (urlcpdId) {
    collegeDetails = useQuery({
      queryKey: ["cpdId"],
      queryFn: () => getCollegeDetails(urlcpdId),
    });
  } else {
    collegeDetails = null;
  }
  const cpdId = collegeDetails?.data?.data?.cpdId;

  const onSubmit = async (data: ICandidate) => {
    setIsFormSubmitted(true);
    try {
      const payload = {
      
        ...data,
        publicid: String(process.env.REACT_APP_PUBLIC_ID),
        source: "cpd",
        typeOfLead: "INTERN",
        mobileNumber: {
          ...state.mobileNumber,
          number: data.mobileNumber,
        },
        _cpd: cpdId,
      };
      await addCandidate(payload);
      navigate("/submitted/");
      outlet?.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      console.log(err);
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const onDrop = async (acceptedFiles: File[]) => {
    try {
      if (acceptedFiles.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          acceptedFiles,
          acceptedFiles[0].name,
          ["doc", "docx", "pdf"],
          10
        );
        setValue("resumes", uploaded.data.split("uploads")[1]);
        trigger("resumes");
      }
    } catch (error) {
      console.log("Error in candidate detail upload", error);
    }
  };
  
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 2; i <= currentYear + 2; i++) {
      years.push(String(i));
    }


  const fields: ICandidateField[] = [
    {
      label: "Name*",
      name: "name",
      type: "input",
      width: 12,
      placeholder: "Type candidate name here",
      wordLimit: 40,
    },
    {
      label: "Email Address*",
      name: "email",
      type: "input",
      width: 12,
      placeholder: "Type candidate email here",
    },
    {
      label: "Mobile Number*",
      name: "mobileNumber",
      numberState: "mobileNumber",
      placeholder: "Type candidate mobile number here",
      type: "mobile-number",
      width: 12,
    },
    {
      label: "Graduation Year*",
      name: "graduationYear",
      type: "select",
      width: 6,
      placeholder: "Type graduation year here",
      children:years.map(year => (
        <MenuItem key={year} value={year}>
        {year}
        </MenuItem>
      ))
    },
    {
      label: "Degree*",
      name: "degree",
      type: "input",
      width: 6,
      placeholder: "Highest College Degree",
      wordLimit: 40,
    },
    {
      label: "Job Title*",
      name: "jobId",
      type: "select",
      width: 12,
      children: jobTitle.map((job: IJobTitle) => (
        <MenuItem key={job._id} value={job._id}>
          {job.title}
        </MenuItem>
      )),
    },
    {
      label: "Job Role*",
      name: "jobRole",
      type: "select",
      width: 12,
      children: [
        <MenuItem key={1} value={"yes"}>
          Six (6) Months Internship followed by Full time opportunity
        </MenuItem>,
      ],
    },
  ];

  const DocumentFields: ICandidateField[] = [
    {
      label: "Resume",
      name: "resumes",
      type: "file",
    },
  ];

    const formValues = watch(["name", "email", "mobileNumber", "graduationYear", "degree", "jobId", "jobRole", "resumes"]);
  
    const isFormValid = formValues.every((value) => value) && isChecked;
  
  return (
    <Box>
      <Box className="main-box">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {fields.map((field) => {
              if (field.type === "input") {
                return (
                  <Grid
                    key={field.label}
                    item
                    xs={12}
                    md={field.width ? field.width : 12}
                  >
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={field.label}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder={field.placeholder}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          {...prop.field}
                          inputProps={
                            {
                              maxLength: field.wordLimit,
                            }
                          }
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.type === "multiline") {
                return (
                  <Grid
                    key={field.label}
                    item
                    xs={12}
                    md={field.width ? field.width : 12}
                  >
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={field.label}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder={field.placeholder}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          multiline
                          minRows={2}
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.type === "mobile-number") {
                const numberState = field.numberState
                  ? field.numberState
                  : "mobileNumber";
                return (
                  <Grid
                    key={field.label}
                    item
                    xs={12}
                    md={field.width ? field.width : 6}
                  >
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <MobileNumber
                          key={field.label}
                          className="disable-text"
                          NumberFieldLabel={field.label}
                          placeholder={field.placeholder}
                          dialCodeValue={`${state[numberState].country}:${state[numberState].iso2}:${state[numberState].dialCode}`}
                          dialCodeName={field.name}
                          onChange={onNumberChange}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          other={prop.field}
                          borderRadius="radius"
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.label === "Job Title*") {
                return (
                  <Grid
                    key={field.label}
                    item
                    xs={12}
                    md={field.width ? field.width : 12}
                  >
                    <Select
                      control={control}
                      name={field.name}
                      label={field.label}
                      size="small"
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    >
                      {field.children}
                    </Select>
                  </Grid>
                );
              } else if (field.label === "Job Role*") {
                return (
                  <Grid
                    key={field.label}
                    item
                    xs={12}
                    md={field.width ? field.width : 12}
                  >
                    <Select
                      control={control}
                      name={field.name}
                      label={field.label}
                      size="small"
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    >
                      {field.children}
                    </Select>
                  </Grid>
                );
              } else {
                return (
                  <Grid key={field.label} item xs={12} md={field.width ? field.width : 12}>
                    <Select
                      control={control}
                      name={field.name}
                      label={field.label}
                      size="small"
                      variant="outlined"
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    >
                      {field.children}
                    </Select>
                  </Grid>
                );
              }
            })}

            {DocumentFields.map((field) => {
              const hasValue = getValues(field.name);
              const shouldShowLabel = hasValue;

              return (
                <Grid
                  key={field.label}
                  item
                  xs={12}
                  md={field.width ? field.width : 12}
                >
                  <div>
                    <div className="upload">
                      {shouldShowLabel && <label>{field.label}</label>}
                    </div>
                    <Grid container spacing={2}>
                      {hasValue && (
                        <Grid item xs={12} md={12}>
                          <Link
                            href={
                              String(process.env.REACT_APP_S3_BASE_URL) +
                              getValues(field.name)?.toString()
                            }
                            target="_blank"
                            underline="none"
                            color="inherit"
                          >
                            <Button variant="outlined" fullWidth>
                              Preview
                            </Button>
                          </Link>
                        </Grid>
                      )}
                      {!hasValue && (
                        <Grid item xs={12} md={12}>
                          <Dropzone
                            onDrop={onDrop}
                            accept={{
                              "image/png": [".png"],
                              "image/jpg": [".jpg"],
                              "image/jpeg": [".jpeg"],
                              "application/pdf": [".pdf"],
                              "application/msword": [".doc"],
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                [".docx"],
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Box className="upload-dropzone">
                                  <Box className="upload-text">
                                    <UploadFileIcon sx={{ fontSize: 40 }} />
                                    <Typography className="upload-resume">
                                    Upload Your Resume or
                                    </Typography>
                                    <Typography >
                                    drag and drop here
                                    </Typography>
                                  </Box>
                                </Box>
                              </div>
                            )}
                          </Dropzone>
                        </Grid>
                      )}
                      {!hasValue && isFormSubmitted && (
                        <Grid item xs={12} md={12}>
                          <FormHelperText
                            error={errors[field.name] ? true : false}
                          >
                            {errors[field.name]?.message}
                          </FormHelperText>
                        </Grid>
                      )}
                      {hasValue && (
                        <Grid item xs={12} md={12}>
                          <Button
                            onClick={() => {
                              setValue(field.name, "");
                              trigger(field.name);
                            }}
                            variant="outlined"
                            color="error"
                            fullWidth
                          >
                            Delete
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Grid>
              );
            })}

            <Grid item xs={12} md={12} className="check-box">
              <Checkbox edge="end" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
              <div className="check-box-data"><p>You agree to our friendly <span>privacy policy</span></p></div>
            </Grid>

            <Grid item xs={12} md={12} className="submit-btn">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                disabled={!isFormValid}
                onClick={() => setIsFormSubmitted(true)}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddCandidate;
